import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const styles = (theme) => ({
    leftLink: {
        display: 'flex',
        alignItems: 'center',
    },
    symecloud: {
        fontSize: 25,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(3),
    },
});

export const TopbarLogoAndTitle = withStyles(styles)(({ classes }) => (
    <>
        <Link
            variant="h6"
            underline="none"
            className={classes.leftLink}
            href="/"
        >
            <img src="/img/logo.png" alt="logo" width="60" height="50" />
            <span className={classes.symecloud}>SymeCloud</span>
        </Link>
    </>
));
