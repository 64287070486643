import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/mui/Typography';
import TextField from '../components/mui/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.cool.main,
    },
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: 'flex',
    },
    iconsWrapper: {
        height: 120,
    },
    icons: {
        display: 'flex',
    },
    icon: {
        maxWidth: 48,
        maxHeight: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    iconImage: {
        maxWidth: 22,
        maxHeight: 22,
        margin: 13,
    },
    list: {
        margin: 0,
        listStyle: 'none',
        padding: 0,
    },
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    language: {
        marginTop: theme.spacing(1),
        width: 150,
    },
}));

const LANGUAGES = [
    // {
    //     code: 'en-US',
    //     name: 'English',
    // },
    {
        code: 'en-US',
        name: 'English',
    },
];

const Copyright = () => (
    <>
        <Typography variant='h6'>
            © SymeCloud Limited, HK <br/>
            <br/>
            Address: 9th Floor, Amtel Buillding, 148 Des Voeux Road Central, Central, HK.
            {' '}
            {new Date().getFullYear()}
        </Typography>
    </>
);

export default function AppFooter() {
    const classes = useStyles();

    return (
        <Typography component="footer" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={2} xs={{color: 'inherit'}}>
                    <Grid item xs={10} sm={8} md={6}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-end"
                            className={classes.iconsWrapper}
                            style={{ flexWrap: 'nowrap' }}
                            spacing={2}
                        >
                            <Grid item>
                                <Typography variant="caption" marked="left">
                                    <Copyright />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <Typography variant="h6" marked="left" gutterBottom>
                            Legal
                        </Typography>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Link href="#">Terms</Link>
                            </li>
                            <li className={classes.listItem}>
                                <Link href="/articles/misc/privacy">Privacy</Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                        <Typography variant="h6" marked="left" gutterBottom>
                            Language
                        </Typography>
                        <TextField
                            select
                            SelectProps={{
                                native: true,
                            }}
                            className={classes.language}
                        >
                            {LANGUAGES.map((language) => (
                                <option value={language.code} key={language.code}>
                                    {language.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                {'This site made by '}
                                <Link href="https://www.artanis.dev" rel="sponsored" title="GNU Artanis">
                                    GNU Artanis
                                </Link>
                                {' is licensed by '}
                                <Link
                                    href="https://www.gnu.org/licenses/gpl-3.0.en.html"
                                    title="GNU General Public license version 3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' GPLv3.'}
                                </Link>
                                <Link href="https://gitlab.com/SymeCloud/syme-site" rel="sponsored" title="Source code">
                                    {' (Source code) '}
                                </Link>
                            </Typography>
                            <br />
                            <Typography variant="caption">
                                {'Icons made by '}
                                <Link href="https://www.freepik.com" rel="sponsored" title="Freepik">
                                    Freepik
                                </Link>
                                {' from '}
                                <Link href="https://www.flaticon.com" rel="sponsored" title="Flaticon">
                                    www.flaticon.com
                                </Link>
                                {' is licensed by '}
                                <Link
                                    href="https://creativecommons.org/licenses/by/3.0/"
                                    title="Creative Commons BY 3.0"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    CC 3.0 BY
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}
