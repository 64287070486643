import { createMuiTheme as createTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
    green, grey, red,
} from '@material-ui/core/colors';

import { enhanceTheme } from '../createTheme';
/**
 * 默认的主题应该是继承了MUI Theme里面的内容（透过createMuiTheme）
 */
const BaseTheme = createTheme({
    palette: {
        primary: {
            main: '#29282d',
            hover: 'rgba(255,255,255,0.08)',
        },
        mainButton: {
            main: '#000000',
            hover: '#525252',
        },
        secondary: {
            main: '#525252',
        },
        cool: {
            main: '#6769A8'
        },
        warning: {
            main: '#d3927e',
            dark: '#ffb25e',
        },
        error: {
            main: '#894a5b',
        },
        success: {
            xLight: green[50],
            main: green[500],
            dark: green[700],
        },
        body: {
            background: '#F1EDE9',
        },
        text: {
            primary: "#625e5f"
        },
    },
    typography: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 18,
        fontWeightLight: 399, // Work Sans
        fontWeightRegular: 500, // Work Sans
        fontWeightMedium: 700, // Roboto Condensed
        fontFamilySecondary: "'Roboto Condensed', sans-serif",
    },
});

// 基于MUI主题的样式做样式定制。
const createDefaultTheme =
      (baseTheme) => enhanceTheme(
          baseTheme,
          (theme) => ({
              typography: {
                  fontHeader: {
                      color: theme.palette.secondary.main,
                      fontWeight: theme.typography.fontWeightMedium,
                      fontFamily: theme.typography.fontFamilySecondary,
                      textTransform: 'uppercase',
                  },
              },
          }),
          (theme) => ({
              palette: {
                  ...theme.palette,
                  background: {
                      ...theme.palette.background,
                      default: theme.palette.common.white,
                      placeholder: grey[200],
                  },
              },
              title: {
                  color: theme.palette.secondary.main,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontFamily: theme.typography.fontFamilySecondary,
                  letterSpacing: 0,
                  fontSize: 60,
              },
              typography: {
                  ...theme.typography,
                  ...theme.typography.fontHeader,
                  title: {
                      color: theme.palette.secondary.main,
                      fontWeight: theme.typography.fontWeightMedium,
                      fontFamily: theme.typography.fontFamilySecondary,
                      letterSpacing: 0,
                      fontSize: 60,
                  },
                  h1: {
                      ...theme.typography.h1,
                      ...theme.typography.fontHeader,
                      letterSpacing: 0,
                      fontSize: 60,
                  },
                  h2: {
                      ...theme.typography.h2,
                      ...theme.typography.fontHeader,
                      fontSize: 48,
                  },
                  h3: {
                      ...theme.typography.h3,
                      ...theme.typography.fontHeader,
                      fontSize: 42,
                  },
                  h4: {
                      ...theme.typography.h4,
                      ...theme.typography.fontHeader,
                      fontSize: 30,
                  },
                  h5: {
                      ...theme.typography.h5,
                      fontSize: 20,
                      fontWeight: theme.typography.fontWeightLight,
                  },
                  h6: {
                      ...theme.typography.h6,
                      fontFamily: theme.typography.fontFamilySecondary,
                      fontSize: 18,
                      textTransform: 'none',
                      color: '#DAD2CF'
                  },
                  foottext: {
                      ...theme.typography.typography,
                      fontSize: 22,
                      textTransform: 'none',
                      color: '#F1EDE9'
                  },
                  subtitle1: {
                      ...theme.typography.subtitle1,
                      color: theme.palette.primary.main,
                      fontSize: 22,
                  },
                  body1: {
                      ...theme.typography.body2,
                      fontWeight: theme.typography.fontWeightRegular,
                      fontSize: 20,
                      color: theme.palette.primary.main,
                  },
                  body2: {
                      ...theme.typography.body1,
                      color: theme.palette.primary.main,
                      fontSize: 18,
                  },
                  poststitle: {
                      ...theme.typography.h3,
                      ...theme.typography.fontHeader,
                      color: theme.palette.secondary.main,
                      fontSize: 20,
                  },
                  postsdate: {
                      ...theme.typography.h5,
                      color: theme.palette.secondary.main,
                      fontSize: 10,
                  },
                  postscontent: {
                      color: theme.palette.primary.main,
                      fontSize: 15,
                  },
                  doctitle: {
                      ...theme.typography.h4,
                      ...theme.typography.fontHeader,
                      color: theme.palette.common.secondary,
                      fontSize: 20,
                  },
                  blogtitle: {
                      ...theme.typography.h3,
                      ...theme.typography.fontHeader,
                      fontWeight: theme.typography.fontWeightMedium,
                      color: theme.palette.primary.main,
                      fontSize: 18,
                  },
                  blogdate: {
                      ...theme.typography.h5,
                      color: theme.palette.common.primary,
                      fontSize: 10,
                  },
                  blogcontent: {
                      color: theme.palette.common.primary,
                      fontSize: 13,
                  },
                  newstitle: {
                      ...theme.typography.h3,
                      ...theme.typography.fontHeader,
                      fontWeight: theme.typography.fontWeightMedium,
                      color: theme.palette.primary.main,
                      fontSize: 18,
                  },
                  newsdate: {
                      ...theme.typography.h5,
                      color: theme.palette.common.primary,
                      fontSize: 10,
                  },
                  newscontent: {
                      color: theme.palette.common.primary,
                      fontSize: 13,
                  },
              },
              topbar: {
                  minHeight: 64,
              },
          }));

export const DefaultTheme = createDefaultTheme(BaseTheme);

export default DefaultTheme;
