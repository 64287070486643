import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Link,
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    CardActionArea,
    IconButton,
    Avatar,
    Collapse,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import Container from '@material-ui/core/Container';

import { toDateTime } from 'scripts/utils/datetime';
import Typography from 'scripts/modules/components/mui/Typography';

const styles = (theme) => ({
    media: {
        height: 65,
        width: 80,
        // paddingTop: '56.25%', // 16:9
    },
    root: {
        // maxWidth: 345,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
    },
});

const ValueCard = ({ card }) => (
    <Grid item xs={12} md={4}>
        <CardActionArea href={card.url}>
            <Card className={styles.root}>
                <CardMedia
                    className={styles.media}
                    component="img"
                    image={card.pic}
                />
                <CardHeader
                    title={(
                        <Typography variant="newstitle" component="h5">
                            {card.title}
                        </Typography>
                    )}
                    subheader={(
                        <Typography variant="newsdate" component="h5">
                            {toDateTime(card.date)}
                        </Typography>
                    )}
                />
                <CardContent>
                    <Typography variant="newscontent" component="h6">
                        {card.abstract}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton
                        aria-label="share"
                        className={styles.button}
                        component="a"
                        href={`https://twitter.com/share?url=syme.dev${card.url}`}
                    >
                        <ShareIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </CardActionArea>
    </Grid>
);

class Posts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: 'nothing',
        };
    }

    componentDidMount() {
        fetch('/news/latest/3')
            .then((res) => res.json())
            .then(
                (result) => {
                    const { posts } = result;
                    const v = posts.map((e) => (
                        <ValueCard card={e} />
                    ));
                    if (posts.length > 0) {
                        this.setState({
                            isLoaded: true,
                            data: v,
                        });
                    } else {
                        return [];
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                },
            );
    }

    render() {
        const { error, isLoaded, data } = this.state;

        if (error) {
            return (
                <div>
                    Error:
                    {error.message}
                </div>
            );
        } if (!isLoaded) {
            return <div>Loading...</div>;
        }
        return data;
    }
}

const News = (props) => {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Container className={classes.container}
                       sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#DAD2CF' }}
            >
                <Typography variant="h4" marked="center" className={classes.title} component="h2">
                    The latest news
                </Typography>
                <div>
                    <Grid container spacing={4}>
                        <Posts />
                    </Grid>
                </div>
            </Container>
        </section>
    );
};

News.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(News);
