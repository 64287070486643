import clsx from 'clsx';

// 样式函数
export const getImageLinkClass = (classes) => classes.imageLink;
export const getRightLinkClass = (classes) => classes.rightLink;
export const getSecondaryRightLinkClass = (classes) => clsx(
  classes.rightLink, classes.linkSecondary,
);

// 根据itemStyle来决定样式
export const getItemStyleClass = (classes, item) => {
  if (item.itemStyle === 'imagelink') {
    return getImageLinkClass(classes);
  }
  if (item.itemStyle === 'secondary') {
    return getSecondaryRightLinkClass(classes);
  }
  // itemStyle === 'primary' or default
  return getRightLinkClass(classes);
};
