import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from 'scripts/modules/components/mui/Typography';

const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

function ProductValues(props) {
    const { classes } = props;

    return (
        <Box
            component="section"
            sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#DAD2CF' }}
        >
            <Container className={classes.container}>
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={4}>
                            <Box sx={item}>
                                <Box
                                    component="img"
                                    src="/img/industry-40.png"
                                    alt="Industry 4.0"
                                    sx={{ height: 150 }}
                                />
                                <Typography variant="h4" sx={{ color: 'inherit'}}>
                                    <Box sx={{ lineHeight: 3 }}> 工业 4.0 </Box>
                                </Typography>
                                <Typography variant="h5">
                                    - 物联网 <br/>
                                    - 边缘计算 <br/>
                                    - 容器编排 <br/>
                                    - 高级防御
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={item}>
                                <Box
                                    component="img"
                                    src="/img/financial-cloud.png"
                                    alt="Financial cloud"
                                    sx={{ height: 150 }}
                                />
                                <Typography variant="h4" sx={{ color: 'inherit' }}>
                                    <Box sx={{ lineHeight: 3 }}> 金融云 </Box>
                                </Typography>
                                <Typography variant="h5">
                                    - 可信计算 <br/>
                                    - 安全基线 <br/>
                                    - 容器打包 <br/>
                                    - 弹性扩容
				</Typography>
                            </Box>
			</Grid>
			<Grid item xs={12} md={4}>
                            <Box sx={item}>
				<Box
                                    component="img"
                                    src="/img/smart-car.png"
                                    alt="Smart car cloud"
                                    sx={{ height: 150 }}
				/>
				<Typography variant="h4" sx={{ color: 'inherit' }}>
                                    <Box sx={{ lineHeight: 3 }}> 车云一体 </Box>
				</Typography>
				<Typography variant="h5">
                                    - SOA <br/>
                                    - 跨域云 <br/>
                                    - 跨车云 <br/>
                                    - 安全可控
				</Typography>
                            </Box>
			</Grid>
                    </Grid>
		</div>
            </Container>
        </Box>
    );
}

export default withStyles(styles)(ProductValues);
