import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Button from 'scripts/modules/components/mui/Button';
import Typography from 'scripts/modules/components/mui/Typography';

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
    },
    title: {
        marginBottom: theme.spacing(14),
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    imageContainer: {
        minHeight: 225,
    },
    image: {
        maxWidth: '100%',
        height: 150,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
        opacity: 0.7,
    },
    button: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(8),
    },
});

const HowItWorksCard = (props) => {
    const {
        classes,
        stepNumber,
        stepName,
        stepImgSrc,
        stepImgAlt,
        stepDesc,
    } = props;
    return (
        <Grid item xs={12} md={4}>
            <div className={classes.item}>
                <div className={classes.number}>
                    {stepName}
                </div>
                <div className={classes.imageContainer}>
                    <img
                        src={stepImgSrc}
                        alt={stepImgAlt}
                        className={classes.image}
                    />
                </div>
                <Typography variant="h5" align="left">
                    {stepDesc}
                </Typography>
            </div>
        </Grid>
    );
};

function ProductHowItWorks(props) {
    const { classes } = props;

    const stepDataList = [
        {
            stepNumber: 1,
            stepName: 'Secure',
            stepImgSrc: '/img/cyber-security.png',
            stepImgAlt: 'Secure',
            stepDesc: 'SymeCloud has a robust network of IT security partners, ensuring a secure and dependable service for safeguarding our customer\'s privacy.'
        },
        {
            stepNumber: 2,
            stepName: 'Vendor Independent',
            stepImgSrc: '/img/unlocked.png',
            stepImgAlt: 'Vendor independent',
            stepDesc: 'SymeCloud offers flexible and customizable multi-cloud and private cloud options, avoiding vender lock-in and promoting decentralization.'
        },
        // {
        //     stepNumber: 3,
        //     stepName: 'Expertise',
        //     stepImgSrc: '/img/expert.png',
        //     stepImgAlt: 'Expertise',
        //     stepDesc: 'SymeCloud team possesses 10 years of experience providing high quality enterprise-level services and a strong, in-depth background in opensource software development.'
        // },
        // {
        //     stepNumber: 4,
        //     stepName: 'System Durabiity',
        //     stepImgSrc: '/img/data-processing.png',
        //     stepImgAlt: 'System Durability',
        //     stepDesc: 'SymeCloud offers busineses a highly durable and dependable platform, ensuring their operations run smoothly and their data is always available with System Durability.'
        // },
        // {
        //     stepNumber: 5,
        //     stepName: 'Operational Visibility',
        //     stepImgSrc: '/img/spyware.png',
        //     stepImgAlt: 'Operational Visibility',
        //     stepDesc: 'SymeCloud enables businesses to achieve complete Operational Visibility, with advanced tools for audit, asset management, and monitoring, helping to streamline operations and reach customer\'s goals.'
        // },
        {
            stepNumber: 3,
            stepName: 'AI powered',
            stepImgSrc: '/img/data-analysis.png',
            stepImgAlt: 'AI powered',
            stepDesc: 'SymeCloud provides AI-powered solution with powerful LLM capabilities, including integration with IoT and Edge computing, helping them to turn their data into actionable insights, drive growth, and make informed decisions.'
        },
    ];

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography variant="h4" marked="center" className={classes.title} component="h2">
                    Why choose us?
                </Typography>
                <div>
                    <Grid container spacing={5}>
                        {
                            stepDataList.map((cardData) => (
                                <HowItWorksCard
                                    key={cardData.stepNumber}
                                    classes={classes}
                                    {...cardData}
                                />
                            ))
                        }
                    </Grid>
                </div>
            </Container>
        </section>
    );
}

ProductHowItWorks.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
