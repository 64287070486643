import React from 'react';

import AppFooter from 'scripts/modules/views/AppFooter';
import { HomeTopbar } from 'scripts/modules/layout/topbar/Topbar';
import { CssBaseline, GlobalStyles } from '@mui/material';

import ProductHero from './ProductHero';
import ProductValues from './ProductValues';
import ProductHowItWorks from './ProductHowItWorks';
import Ecosystem from './Ecosystem';
import Blogs from './Blogs';
import News from './News';
import Intro from './Intro';
import Intro2 from './Intro2';
import Contact from './Contact';

export const Home = () => (
    <>
        <CssBaseline />
        <GlobalStyles
            styles={{
                body: { backgroundColor: '#F1EDE9' }
            }}
        />
        <HomeTopbar />
        <ProductHero />
        <Intro />
        <ProductHowItWorks />
        <Blogs />
        <Ecosystem />
        <Contact />
        <AppFooter />
    </>
);
