import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Button from 'scripts/modules/components/mui/Button';
import Typography from 'scripts/modules/components/mui/Typography';

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
    },
    title: {
        marginBottom: theme.spacing(14),
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    imageContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 225,
    },
    image: {
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '100%',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
        opacity: 0.7,
    },
    button: {
        color: theme.palette.common.white,
        marginTop: theme.spacing(8),
    },
});

const HowItWorksCard = (props) => {
    const {
        classes,
        stepNumber,
        stepImgSrc,
        stepImgAlt,
        stepDesc,
    } = props;
    return (
        <Grid item xs={12} md={4}>
            <div className={classes.item}>
                <div className={classes.imageContainer}>
                    <img width="260"
                         src={stepImgSrc}
                         alt={stepImgAlt}
                         className={classes.image}
                    />
                </div>
            </div>
        </Grid>
    );
};

function ProductHowItWorks(props) {
    const { classes } = props;

    const stepDataList = [
        {
            stepImgSrc: '/img/hardenedlinux.svg',
            stepImgAlt: 'HardenedLinux',
        },
        {
            stepImgSrc: '/img/hv.webp',
            stepImgAlt: 'HardenedVault',
        },
        {
            stepImgSrc: '/img/kubernetes-stacked-color.svg',
            stepImgAlt: 'Kubernetes',
        },
        {
            stepImgSrc: '/img/final-logo-complete.svg',
            stepImgAlt: 'GNU Artanis',
        },
        {
            stepImgSrc: '/img/animula.svg',
            stepImgAlt: 'Animula',
        },
        {
            stepImgSrc: '/img/alexon.png',
            stepImgAlt: 'Alexon',
        },
        {
            stepImgSrc: '/img/Guix.png',
            stepImgAlt: 'GNU Guix',
        },
    ];

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography variant="h4" marked="center" className={classes.title} component="h2">
                    Ecosystem
                </Typography>
                <div>
                    <Grid container spacing={5}>
                        {
                            stepDataList.map((cardData) => (
                                <HowItWorksCard
                                    key={cardData.stepNumber}
                                    classes={classes}
                                    {...cardData}
                                />
                            ))
                        }
                    </Grid>
                </div>
            </Container>
        </section>
    );
}

ProductHowItWorks.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
