import { merge } from 'lodash-es';

/**
 * 通过transform的方法，增强主题对象的配置。
 *
 * @param oldTheme 老的主题
 * @param enhancements 0个或多个增强方法，可以是函数或对象。如果为函数，则
 *                     合并老的主题和函数的返回值；如果
 */
export const enhanceTheme = (oldTheme, ...enhancements) => {
  const newTheme = enhancements.reduce((theme, enhance) => {
    // 增强：回调方式
    if (typeof enhance === 'function') {
      return merge(theme, enhance(theme));
    }
    // 增强：合并对象方式
    return merge(theme, enhance);
  }, { ...oldTheme });

  return newTheme;
};
