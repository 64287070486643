import * as React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from 'scripts/modules/components/mui/Typography';
import theme from './../../theme';

const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    featuretitle: {
        fontSize: 26,
        fontWeight: theme.typography.fontWeightMedium,
    },
    featurecontent: {
        fontSize: 21,
    },
});

function Intro(props) {
    const { classes } = props;

    return (
        <Box
            component="section"
            sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#DAD2CF' }}
        >
            <Container className={classes.container}>
                <Typography variant="h5">
                    SymeCloud is an AI-Infra provider based on cloud native technology. SymeCloud is designed to help businesses, developers, and individuals take advantage of the latest web3, cloud native, AI, and decentralization technologies.
                </Typography>
                <br />
                <br />
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/img/ai-auto.png"
                                alt="AI-powered automation"
                                sx={{ height: 150 }}
                            />
                            <Typography class={classes.featuretitle}
                                        sx={{ color: 'inherit' }}>
                                <Box sx={{ lineHeight: 3 }}> AI-Powered automation </Box>
                            </Typography>
                            <Typography class={classes.featurecontent}>
                                SymeCloud leverages AI to automate repetitive tasks and streamline operations, reducing manual effort and increasing efficiency.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/img/financial-cloud.png"
                                alt="Financial cloud"
                                sx={{ height: 150 }}
                            />
                            <Typography class={classes.featuretitle}
                                        sx={{ color: 'inherit' }}>
                                <Box sx={{ lineHeight: 3 }}> Web3 Integration </Box>
                            </Typography>
                            <Typography class={classes.featurecontent}>
                                SymeCloud integrates seamlessly with Web3 technologies and applications, making it easy for users to access the latest decentralized services and applications directly from the cloud.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/img/industry-40.png"
                                alt="Industry 4.0"
                                sx={{ height: 150 }}
                            />
                            <Typography class={classes.featuretitle}
                                        sx={{ color: 'inherit'}}>
                                <Box sx={{ lineHeight: 3 }}> Industry 4.0 </Box>
                            </Typography>
                            <Typography class={classes.featurecontent}>
                                SymeCloud is highly scalable and flexible, making it easy for business to adapt to changing market conditions. We provide solid solution to protect your data and privacy.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default withStyles(styles)(Intro);
