import React from 'react';
import clsx from 'clsx';

import {
    Button, Drawer, List, ListItem, Link,
    Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getItemStyleClass } from './commonStyles';
import TopbarButton from './TopbarButton';

const TOP_ITEM_HEIGHT = 48;
const SUB_ITEM_HEIGHT = 44;

// 通用的菜单项样式
const getMenuItemStyle = (theme) => ({
    minHeight: TOP_ITEM_HEIGHT,
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'none',
        background: theme.palette.mainButton.hover,
    },
});

// 附加链接样式
const getLinkItemStyles = (theme) => ({
    // 普通链接样式
    rightLink: {

    },
    // 图片链接样式
    imageLink: {
        ...theme.typography.h6,
        '& img:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    // 旧的高亮颜色
    linkSecondary: {
        color: theme.palette.secondary.main,
    },
});

const styles = (theme) => ({
    topbarMenuDrawer: {
        width: 250,
        padding: 0,
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
    },
    navMenuItem: {
        ...getMenuItemStyle(theme),
    },
    ...getLinkItemStyles(theme),
    grow: {
        flexGrow: 1,
    },
});

const TopLink = withStyles(styles)(({ navItem, classes, onClose }) => (
    <ListItem
        className={clsx(
            classes.navMenuItem,
            getItemStyleClass(classes, navItem),
        )}
        onClick={onClose}
        component={Link}
        href={navItem.link}
    >
        {navItem.img && <img src={navItem.img} alt={navItem.label} />}
        <span>{navItem.label}</span>
    </ListItem>
));

/* 包含子菜单的项目 */
const SubMenuAccordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(Accordion);

const SubMenuAccordianSummary = withStyles((theme) => ({
    root: {
        '&$expanded': {
            minHeight: TOP_ITEM_HEIGHT,
        },
    },
    content: {
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
    expandIcon: {
        color: theme.palette.common.white,
    },
}))(AccordionSummary);

const SubMenuItemStyles = (theme) => ({
    itemRoot: {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
    },
    navMenuItem: {
        ...getMenuItemStyle(theme),
    },
    subNavMenuItem: {
        boxShadow: '1px solid rgba(0, 0, 0, .125)',
        minHeight: SUB_ITEM_HEIGHT,
    },
    subMenuItemList: {
        flex: 1,
    },
    itemDetails: {
        padding: 0,
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    ...getLinkItemStyles(theme),
});

const SubMenuItems = withStyles(SubMenuItemStyles)(({
    navItem,
    classes,
    onClose,
}) => {
    const [expanded, setExpanded] = React.useState(true);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <SubMenuAccordion
            square
            className={classes.itemRoot}
            expanded={expanded}
            onChange={toggleExpanded}
        >
            <SubMenuAccordianSummary
                className={clsx(
                    classes.navMenuItem,
                    classes.itemSummary,
                )}
                expandIcon={<ExpandMoreIcon />}
            >
                {navItem.label}
            </SubMenuAccordianSummary>
            <AccordionDetails
                className={classes.itemDetails}
            >
                <List className={classes.subMenuItemList}>
                    {navItem.subItems.map((subItem) => (
                        <ListItem
                            className={clsx(
                                classes.navMenuItem,
                                classes.subNavMenuItem,
                                getItemStyleClass(classes, subItem),
                            )}
                            component={Link}
                            href={subItem.link}
                            onClick={onClose}
                        >
                            {subItem.label}
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </SubMenuAccordion>
    );
});

const CompactMenuList = withStyles(styles)(({ classes, navItems }) => (
    <List component="nav" style={{ padding: 0 }}>
        {navItems.map((item) => {
            if (item.subItems && item.subItems.length > 0) {
                return <SubMenuItems key={item.label} navItem={item} />;
            }
            return <TopLink key={item.label} navItem={item} />;
        })}
    </List>
));

/**
 * 不完整宽度的顶栏菜单（ipad、mobile）
 */
export const CompactScreenMenu = withStyles(styles)(({ navItems = [], classes }) => {
    const [drawerVisible, setDrawer] = React.useState(false);

    const toggleDrawer = (flag) => () => {
        setDrawer(flag);
    };

    return (
        <>
            <TopbarButton
                disableRipple
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </TopbarButton>
            <Drawer
                anchor="right"
                PaperProps={{
                    className: classes.topbarMenuDrawer,
                }}
                open={drawerVisible}
                onClose={toggleDrawer(false)}
            >
                <TopLink
                    navItem={{
                        label: 'Home',
                        link: '/',
                    }}
                />
                <CompactMenuList
                    navItems={navItems}
                    onSpaceClick={toggleDrawer(false)}
                />
                <div
                    className={classes.grow}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                />
            </Drawer>
        </>
    );
});
