import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

import Typography from 'scripts/modules/components/mui/Typography';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(9),
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
});

function Contact(props) {
    const { classes } = props;

    return (
        <Container className={classes.root} component="section" data-name="smoking-hero">
            <Button className={classes.button}>
                <Typography variant="h4" component="span">
                    Contact
                </Typography>
            </Button>
            <Typography variant="h5" className={classes.link}>
                business@syme.dev
            </Typography>
        </Container>
    );
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
