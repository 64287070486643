import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from 'scripts/modules/components/mui/Typography';

const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

function Intro2(props) {
    const { classes } = props;

    return (
        <Box
            component="section"
            sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#DAD2CF' }}
        >
            <Container className={classes.container}>
                <Typography variant="h5">
                    SymeCloud places a strong emphasis on security and privacy, providing businesses with a secure platform for managing their data.
                </Typography>
            </Container>
        </Box>
    );
}

export default withStyles(styles)(Intro2);
