import clsx from 'clsx';

/**
 * 网站顶栏的菜单配置（数据和视图分离）
 *
 * @type {Array}
 */
export const TopbarNavItems = [
    {
        label: 'Product',
        subItems: [
            {
                label: 'Syme Chat',
                link: 'https://aws.amazon.com/marketplace/pp/prodview-gzehsbllm3q3o',
                itemStyle: 'primary',
            },
            {
                label: 'Alexon EE',
                link: '/articles/misc/alexon',
                itemStyle: 'primary'
            },
            {
                label: 'Alonzo DevKit',
                link: '/articles/misc/alonzo',
                itemStyle: 'primary'
            }
        ],
    },
    {
        label: 'Solution',
        subItems: [
            {
                label: 'Artificial Intelligence',
                link: '/articles/misc/ai',
                itemStyle: 'primary',
            },
            {
                label: 'Blockchain Web3',
                link: '/articles/misc/web3',
                itemStyle: 'primary',
            },
            {
                label: 'Industry 4.0',
                link: '/articles/misc/industry40',
                itemStyle: 'primary',
            },
        ],
    },
    // {
    //   label: '白皮书',
    //   link: '/articles/misc/whitepaper',
    //   itemStyle: 'primary',
    // },
    {
        label: 'Blog',
        link: '/articles/blog',
        itemStyle: 'primary',
    },
    {
        label: 'News',
        link: '/articles/news',
        itemStyle: 'primary',
    },
    {
        label: 'OpenSource',
        subItems: [
            {
                label: 'Alexon',
                link: 'https://alexon.dev',
                itemStyle: 'primary',
            },
            {
                label: 'GNU Artanis',
                link: '/articles/misc/artanis',
                itemStyle: 'primary',
            },
            {
                label: 'Animula',
                link: '/articles/misc/animula',
                itemStyle: 'primary',
            },
        ],
    },
    {
        label: 'About',
        link: '/articles/misc/about',
        itemStyle: 'primary',
    },
    {
        label: 'Contact',
        link: '/articles/misc/contact',
        itemStyle: 'primary',
    },
];
