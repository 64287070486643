/**
 * 梳理项目里的样式主题规范，并且提供对外统一的主题样式方法。
 *
 * 不同的样式应该通过继承和merge的方式来进行修改。
 */

import { DefaultTheme } from './default';
// import { enhanceTheme } from './createTheme';

const theme = DefaultTheme;
export default theme;

/*
  在组件里使用样式的方法：

  1、createStyles() 方法，可用于定义组件所需要的样式，最终被withStyles HOC使用。

  createStyles() 方法有两种使用方式（对象或者函数）：

  - 不依赖主题的：
  ```
  const styles = createStyles({
    somePart: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

  ```

  - 依赖于主题的：
  ```
  const styles = (theme) => createStyles({
    someComponent: {
      background: theme.palette.bgWhite,
    }
  })

  ```

  除此以外，createStyles还可以用media-query特性

  2、withStyles(styles, [options]) 方法，实际上是一个高阶组件（HOC）可以传入styles定义，并在
  组件内注入一个”classes“的props，让组件可以使用styles定义里面的样式集合。

  ```
  const styles = createStyles({
    somePart: {
      // ...
    }
  })

  const SomeComponent = withStyles(styles)((props) => {
    const { classes } = props;
    return <div className={classes.somePart} />
  })

  ```

  withStyles

  3、本站自己写 ”enhanceTheme()”方法。当需要创建多个theme的时候（比如会在
  在不同的theme切换），可以使用enhanceTheme(oldTheme, newThemeOptions)方法，
  以保证可以基于基础的主题进行扩展。

  4、<ThemeProvider/>组件和 withTheme() hook。主要是提供了在组件间传递theme对
  象的方法，可以在组件里面（通过context）拿到原始的theme对象。实际上withStyles
  的options里面也可以写上这个，但组件里直接拿这个没什么用，就是单纯一个对象。

  总的来说，还是要用withStyles() HOC来做CSS style-to-class的样式转换。
 */
