import React from 'react';
import ReactDOM from 'react-dom';
import { withRoot } from './modules/withRoot';
import { Home } from './pages/home';
import {
    withTheme, // withStyles,
    ThemeProvider
} from '@material-ui/core/styles';
import { flow } from 'lodash-es';
import theme from 'theme';

function AppHome() {
    return <ThemeProvider theme={theme}>
               <Home />
           </ThemeProvider>
}

const App = withRoot(AppHome);

ReactDOM.render(<App />, document.getElementById('root'));
