/**
 * 新的首页Topbar。
 *
 * 支持移动端、pad和桌面端的展开收齐，支持多层菜单烂
 */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';

import { TopbarLogoAndTitle } from './TopbarLogoAndTitle';
import { TopbarMenu } from './TopbarMenu';
import { TopbarNavItems } from './data/TopbarNavItems';

/**
 * 顶栏样式定义
 */
// 顶栏高度
const getTopbarHeight = (theme) => ({
  minHeight: 64,
  // [theme.breakpoints.up('sm')]: {
  //   minHeight: 70,
  // },
  // [theme.breakpoints.down('sm')]: {
  //   minHeight: 128,
  // },
});
// 最终样式
const styles = (theme) => ({
  placeholder: {
    ...getTopbarHeight(theme),
  },
  toolbar: {
    ...getTopbarHeight(theme),
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    justifyContent: 'space-between',
  },
  grow: {
    flexGrow: 1,
  },
});

export const HomeTopbar = withStyles(styles)(({ classes, ...rest }) => (
  <div className={classes.grow}>
    <AppBar elevation={0} position="fixed">
      <Toolbar className={classes.toolbar}>

        <TopbarLogoAndTitle />

        <div className={classes.grow} />
        <TopbarMenu navItems={TopbarNavItems} />

      </Toolbar>
    </AppBar>
    <div className={classes.placeholder} />
  </div>
));
