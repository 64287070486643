import React from 'react';
import clsx from 'clsx';

import {
  Tabs, Tab, Hidden, Link,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { LargeScreenMenu } from './LargeScreenMenu';
import { CompactScreenMenu } from './CompactScreenMenu';

/**
 * 首页顶栏菜单项。
 */
const styles = (theme) => ({

});

/**
 * 支持顶级菜单、二级和多级菜单，使用data/TopbarNavItems的数据驱动。
 */
export const TopbarMenu = withStyles(styles)(({ classes, navItems }) => (
  <>
    <Hidden smDown>
      <LargeScreenMenu
        classes={classes}
        navItems={navItems}
      />
    </Hidden>
    {/* small screens */}
    <Hidden mdUp>
      <CompactScreenMenu
        classes={classes}
        navItems={navItems}
      />
    </Hidden>
  </>
));

export default TopbarMenu;
