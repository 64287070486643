import React, { useState } from 'react';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import {
    Link, Button, Popper, MenuList, MenuItem, ClickAwayListener,
    Grow, Paper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { getItemStyleClass } from './commonStyles';

const TopbarMenuDownButton = ExpandMoreIcon;

// 导航菜单样式
const styles = (theme) => ({
    // 菜单容器
    navItemContainer: {
        // '&:not(:first-child)': {
        //   marginLeft: theme.spacing(3),
        // },
    },
    navItemLink: {
        color: theme.palette.common.white,
        // background: theme.palette.primary.main,
    },
    // 默认链接样式
    rightLink: {
        ...theme.typography.h6,
        fontSize: 16,
        borderRadius: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'none',
            background: theme.palette.mainButton.hover,
        },
    },
    // 图片链接样式
    imageLink: {
        minWidth: 30,
        '&:not(:first-child)': {
            marginLeft: theme.spacing(3),
        },
        '& img:not(:last-child)': {
            marginRight: theme.spacing(0.6),
        },
    },
    // 旧的高亮颜色
    linkSecondary: {
        color: theme.palette.secondary.main,
    },
    // 菜单样式
    navMenu: {
        background: theme.palette.primary.main,
        minWidth: 146
    },
});

// 顶级菜单项
const TopLink = withStyles(styles)(({ classes, navItem }) => (
    <div className={classes.navItemContainer}>
        <Button
            component={Link}
            className={clsx(
                classes.navItemLink,
                getItemStyleClass(classes, navItem),
            )}
            href={navItem.link}
        >
            {navItem.img && <img src={navItem.img} alt={navItem.label} />}
            <span>{navItem.label}</span>
        </Button>
    </div>
));

const TopLinkSubMenuContext = React.createContext();

// 多级菜单项
const SubMenuItems = withStyles(styles)(({ classes, navItem = {} }) => {
    const { label, subItems = [] } = navItem;

    const anchorRef = React.useRef(null);
    // const [open, setOpen] = React.useState(false);
    const [subMenu, setSubMenu] = React.useContext(TopLinkSubMenuContext);

    const open = subMenu == label;
    const setOpen = (isOpen) => isOpen ? setSubMenu(label) : setSubMenu(null);

    const handleOpen = React.useCallback(() => setOpen(true));

    const handeLeaveClose = React.useCallback(() => {
        setOpen(false);
    });

    const handleClose = React.useCallback((event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    });


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const controlId = `nav-item-menu-${label}`;

    return (
        <div className={classes.navItemContainer}>
            <Button
                className={clsx(
                    classes.navItemLink,
                    getItemStyleClass(classes, navItem),
                )}
                component={Link}
                href="#"
                ref={anchorRef}
                aria-controls={open ? controlId : null}
                aria-haspopup="true"
                endIcon={<TopbarMenuDownButton />}
                disableRipple
                onClick={handleOpen}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
            >
                {label}
            </Button>
            <Popper
                placement="bottom-start"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'left top' }}
                    >
                        <Paper
                            className={classes.navMenu}
                            square
                            onMouseLeave={handeLeaveClose}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id={controlId}>
                                    {
                                        subItems.map((subItem) => (
                                            <MenuItem
                                                key={subItem.label}
                                                className={clsx(
                                                    classes.navItemLink,
                                                    getItemStyleClass(classes, navItem),
                                                )}
                                                component={Link}
                                                href={subItem.link}
                                                onClick={handleClose}
                                            >
                                                <span>{subItem.label}</span>
                                            </MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
});

/**
 * 完整宽度的菜单。
 *
 * 使用
 */
export const LargeScreenMenu = ({ navItems = [] }) => {
    const subMenuStateObject = React.useState(null);

    return (
        <TopLinkSubMenuContext.Provider value={subMenuStateObject}>
            {
                navItems.map((item) => {
                    if (item.subItems && item.subItems.length > 0) {
                        return <SubMenuItems key={item.label} navItem={item} />;
                    }
                    return <TopLink key={item.label} navItem={item} />;
                })
            }
        </TopLinkSubMenuContext.Provider>
    )
};
