import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from 'scripts/modules/components/mui/Button';
import Typography from 'scripts/modules/components/mui/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage = '/img/bg2.png';

const styles = (theme) => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: theme.palette.secondary,
        backgroundPosition: 'center',
    },
    button: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&:hover': {
            borderColor: theme.palette.cool.main,
        },
        minWidth: 200,
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10),
        },
        fontSize: 30,
    },
    more: {
        marginTop: theme.spacing(2),
    },
});

function ProductHero(props) {
    const { classes } = props;

    return (
        <ProductHeroLayout backgroundClassName={classes.background}>
            {/* Increase the network loading priority of the background image. */}
            <Typography color="inherit" align="center" variant="h2" marked="center">
                Lucid Trusty AI-Infra
            </Typography>
            <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
                Empower the cyber maverick in the trend towards decentralisation.
            </Typography>
            <Button
                className={classes.button}
                variant="outlined"
                size="large"
                component="a"
                href="/articles//blog/"
            >
                Learn more
            </Button>
        </ProductHeroLayout>
    );
}

ProductHero.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
