import React from 'react';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// 定制紫色地白色文字的顶栏按钮
const TopbarButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  label: {
    color: theme.palette.common.white,
  },
  outlined: {
    borderColor: theme.palette.common.white,
    padding: theme.spacing(0.5),
  },
}))(Button);

export default TopbarButton;
